import React from "react";
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { Grid, Cell } from '@trbl/react-css-grid';
import Header from '../components/Header';
import PageLayout from "../layouts/PageLayout";
import SEO from "../components/seo"
import '../scss/pages/404.scss';

const ContentBlock = loadable(() => import('../components/ContentBlock'));

const NotFoundPage = ({ data }) => {
  const { headerSection } = data.page.childMarkdownRemark.frontmatter.content;
  return (
    <PageLayout>
      <SEO title="404: Not found" />
      <Header
        subheading={headerSection.subheading}
        heading={headerSection.heading}
        background={headerSection.background}
        color="green"
      />
      <main className="wmfab__main--no-lazy-load">
        <section>
          <Grid>
            <Cell
              hStart={5}
              hSpan={8}
              hStartM={5}
              hSpanM={9}
              hStartS={2}
              hSpanS={14}
            >
              <ContentBlock
                headerAlign="right"
                header="You just hit a route that doesn&#39;t exist... the sadness."
                bodyAlign="center"
                buttonAlign="center"
                align="center"
                button={{
                  text: "Back to Home",
                  color: "black",
                  url: "/",
                }}
              />
            </Cell>
          </Grid>
        </section>
      </main>
      {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
    </PageLayout>
  );
 };

export const NotFoundPageQuery = graphql`
  query NotFoundPageQuery {
    page: file(sourceInstanceName: {eq: "pages"}, name: {eq: "404"}) {
      childMarkdownRemark {
        frontmatter {
          content {
            headerSection {
              background {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              color
              subheading
              heading
            }
          }
        }
      }
    }
  }
`;

export default NotFoundPage;
